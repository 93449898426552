import { Pipe, PipeTransform } from '@angular/core';
import { Routes } from '../../config/app.constant';

@Pipe({
  name: 'urlTarget',
})
export class UrlTargetPipe implements PipeTransform {
  transform(url: string): string {
    const targetBlankUrls = [Routes.specialisationReport];

    return targetBlankUrls.includes(url) ? '_blank' : '_self';
  }
}
