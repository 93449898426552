import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { MatDrawer } from '@angular/material/sidenav';
import { Module } from '../../../pages/homepage/classes/module';
import { MainModules, Routes } from '../../../config/app.constant';
import { NavigationEnd, Router } from '@angular/router';
import { UserData } from '../../../classes/user-data';
import { AuthService } from '../../modules/auth/auth.service';
import { TableParam } from '../../../classes/table-param';
import { getDefaultTableParams } from '../../../utils/table-param.util';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MainModule } from '../../../pages/homepage/classes/main-module';
import { Role } from '../../../classes/roles';
import { animate, state, style, transition, trigger } from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          opacity: 0,
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  @Input() drawer: MatDrawer;
  @Output() toggleSidebar = new EventEmitter<boolean>();

  user: UserData;
  mainModules: MainModule[] = [];

  tableParams: TableParam = getDefaultTableParams();

  constructor(
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getLoggedInUser();

    if (this.user.additionalInfoRequired || !this.user.selectedRole) {
      this.mainModules = [];
    } else {
      this.mainModules = MainModules.map((mainModule: MainModule) => {
        mainModule.modules = mainModule.modules.filter((module: Module) => {
          return module.roles.includes(this.user.selectedRole) || module.roles.includes(Role.Anyone);
        });

        if (mainModule.modules.length > 0) {
          mainModule.modules = this.sortModulesArray(mainModule.modules) as Module[];
        }

        return mainModule;
      });

      this.mainModules = this.mainModules.filter((mainModule) => mainModule.modules.length > 0);
      this.mainModules = this.sortModulesArray(this.mainModules) as MainModule[];
    }

    this.listenToLanguageChange();

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateMenuState();
      }
    });

    this.updateMenuState();
  }

  updateMenuState(): void {
    const currentUrl = this.router.url;
    this.mainModules.forEach((mainModule: MainModule) => {
      const moduleIsActive = mainModule.modules.some((module) => currentUrl.includes(module.url));
      mainModule.isOpen = moduleIsActive;
      mainModule.isActive = moduleIsActive;
    });
  }

  toggleModule(module: any): void {
    module.isOpen = !module.isOpen;
  }

  sortModulesArray(modules: Module[] | MainModule[]): Module[] | MainModule[] {
    return modules.sort((a, b) =>
      this.translateService.instant(a.title).localeCompare(this.translateService.instant(b.title))
    );
  }

  listenToLanguageChange(): void {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.mainModules = this.sortModulesArray(this.mainModules) as MainModule[];
        this.mainModules.forEach((mainModule: MainModule) => {
          mainModule.modules = this.sortModulesArray(mainModule.modules) as Module[];
        });
        this.updateMenuState();
      },
    });
  }

  toggle(): void {
    this.toggleSidebar.emit(!this.drawer.opened);
  }

  goToHome(): void {
    const currentRoute = window.location.pathname;

    if (currentRoute !== Routes.home) {
      this.router.navigate([Routes.home]);
    } else {
      window.location.reload();
    }
  }
}
