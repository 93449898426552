<nav class="sidenav">
  <div class="sidenav-top">
    <div (click)="goToHome()" class="sidenav-top__icon-title">
      <img src="assets/image/sze_logo2.svg" alt="Sze logo" />
      <span class="title">
        {{ 'Common.AppTitle' | translate }}
      </span>
    </div>
    <button class="sidenav-close-icon" mat-icon-button (click)="toggle()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="items">
    <div *ngFor="let mainModule of mainModules" class="main-module-list">
      <div
        class="d-flex align-items-center justify-content-between main-module"
        [ngClass]="{active: mainModule.isActive}"
        (click)="toggleModule(mainModule)">
        <div class="d-flex align-items-center">
          <mat-icon class="main-module-icon me-2">{{ mainModule.icon }}</mat-icon>
          <div class="main-module-title">{{ mainModule.title | translate }}</div>
        </div>
        <mat-icon class="expand-icon">
          {{ mainModule.isOpen ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </div>

      <div [@expandCollapse]="mainModule.isOpen ? 'expanded' : 'collapsed'" class="module-list">
        <a
          *ngFor="let module of mainModule.modules"
          [routerLinkActiveOptions]="{queryParams: 'ignored',matrixParams: 'exact', paths: 'subset',fragment: 'exact'}"
          [href]="module.url"
          [target]="module.url | urlTarget"
          [routerLink]="module.url"
          [routerLinkActive]="'active'"
          [queryParams]="module.url | withQueryParams"
          class="module d-flex align-items-center"
        >
          <div class="d-flex align-items-center">
            <div>{{ module.title | translate }}</div>
            <mat-icon *ngIf="module.icon" class="ms-2 module-icon">{{ module.icon }}</mat-icon>
          </div>
        </a>
      </div>
    </div>
  </div>
</nav>
