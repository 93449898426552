import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FilterByPermissionPipe } from './pipes/filter-by-permission.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalService } from './services/modal.service';
import { MatDialogModule } from '@angular/material/dialog';
import { SaveButtonComponent } from './components/save-button/save-button.component';
import { CancelButtonComponent } from './components/cancel-button/cancel-button.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { SelectComponent } from './components/select/select.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { WithQueryParamsPipe } from './pipes/with-query-params.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateRolePipe } from './components/header/pipes/translate-role.pipe';
import { MatTreeModule } from '@angular/material/tree';
import { UrlTargetPipe } from './pipes/url-target.pipe';

@NgModule({
  declarations: [FilterByPermissionPipe, WithQueryParamsPipe, TranslateRolePipe, UrlTargetPipe],
  imports: [
    CancelButtonComponent,
    CommonModule,
    ConfirmModalComponent,
    FormsModule,
    LanguageSwitcherComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    SaveButtonComponent,
    SelectComponent,
    TranslateModule,
  ],
  exports: [
    CancelButtonComponent,
    CommonModule,
    ConfirmModalComponent,
    FilterByPermissionPipe,
    FormsModule,
    LanguageSwitcherComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    SaveButtonComponent,
    SelectComponent,
    TranslateModule,
    WithQueryParamsPipe,
    TranslateRolePipe,
    UrlTargetPipe,
  ],
  providers: [ModalService],
})
export class SharedModule {}
