import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/modules/http/http.service';
import { TableParam } from '../../../classes/table-param';
import { ObjectData } from '../../../classes/object-data';
import { Teacher } from '../classes/teacher';
import { Endpoints } from '../../../config/app.constant';
import { tableParamToQueryParam } from '../../../utils/table-param.util';
import { Observable } from 'rxjs';
import { TeacherForm } from '../classes/teacher-form';
import { TeacherView } from '../../teacher-view/classes/teacher-view';
import { Specialisation } from '../../specialisations/classes/specialisation';

@Injectable({
  providedIn: 'root',
})
export class TeachersService {
  constructor(private httpService: HttpService) {}

  getTeachers(tableParams?: TableParam): Observable<ObjectData<Teacher>> {
    return this.httpService.get(
      Endpoints.teachers,
      tableParams ? tableParamToQueryParam(tableParams) : { pageSize: '1000' }
    );
  }

  createTeacher(teacher: TeacherForm): Observable<Teacher> {
    return this.httpService.post(Endpoints.teachers, teacher);
  }

  editTeacher(id: number, teacher: TeacherForm): Observable<Teacher> {
    return this.httpService.put(`${Endpoints.teachers}/${id}`, teacher);
  }

  manageTeacher(teacher: TeacherForm, id?: number): Observable<Teacher> {
    return id ? this.editTeacher(id, teacher) : this.createTeacher(teacher);
  }

  deleteTeacher(id: number): Observable<any> {
    return this.httpService.delete(`${Endpoints.teachers}/${id}`);
  }

  getTeacherView(tableParams?: TableParam): Observable<ObjectData<TeacherView>> {
    return this.httpService.get(
      Endpoints.teacherSubjects,
      tableParams
        ? tableParamToQueryParam(tableParams)
        : {
            pageSize: '1000',
            scope: 'own',
          }
    );
  }

  getCurrentSpecialisations(teacherId: number): Observable<ObjectData<Partial<Specialisation>>> {
    return this.httpService.get(`${Endpoints.teachers}/${teacherId}/current-specialisations`);
  }
}
